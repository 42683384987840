import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import { Avatar, IconButton } from '@material-ui/core'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import ChatIcon from '@material-ui/icons/Chat'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import SidebarChat from './SidebarChat'
import db from './firebase'
import { useStateValue } from './StateProvider'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { auth } from './firebase'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import { useHistory } from 'react-router-dom'
import { actionTypes } from './reducer'

function Sidebar() {
  const [rooms, setRooms] = useState([])
  const [{ user }, dispatch] = useStateValue()

  useEffect(() => {
    const unsubscribe = db.collection('rooms').onSnapshot(snapshot => setRooms(
      snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }))
    ))

    return () => unsubscribe()
  }, [])

  const history = useHistory()

  const logout = () => {
    auth.signOut()
      .then(() => {
        history.push('/')
        dispatch({
          type: actionTypes.SET_USER,
          user: null
        })
      })
  }

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return <div className='sidebar'>
    <div className='sidebar__header'>
      <Avatar
        src={user?.photoURL}
        onClick={handleToggle}

        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
      />

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className='sidebar__menu'>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <div className='sidebar__headerRight'>
        <IconButton>
          <DonutLargeIcon />
        </IconButton>
        <IconButton>
          <ChatIcon />
        </IconButton>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </div>
    </div>

    <div className='sidebar__search'>
      <div className='sidebar__searchContainer'>
        <SearchOutlined />
        <input placeholder='Найдите или начните чат' type='text' />
      </div>
    </div>

    <div className='sidebar__chats'>
      <SidebarChat addNewChat />
      {rooms.map(room => (
        <SidebarChat key={room.id} id={room.id} name={room.data.name} />
      ))}
    </div>

  </div>
}

export default Sidebar
