import React, { useState, useEffect, useRef } from 'react'
import './Chat.css'
import { Avatar, IconButton } from '@material-ui/core'
import { AttachFile, MoreVert, SearchOutlined, InsertEmoticon, Mic } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
import db from './firebase'
import { useStateValue } from './StateProvider'
import firebase from 'firebase'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

function Chat() {
  const [seed, setSeed] = useState('')
  const [input, setInput] = useState('')
  const { roomId } = useParams()
  const [roomName, setRoomName] = useState('')
  const [messages, setMessages] = useState([])
  const [{ user }, dispatch] = useStateValue() // eslint-disable-line
  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (roomId) {
      db.collection('rooms').doc(roomId).onSnapshot(snapshot => {
        setRoomName(snapshot.data().name)
      })

      db.collection('rooms')
        .doc(roomId)
        .collection('messages')
        .orderBy('timestamp', 'asc')
        .onSnapshot(snapshot => (
          setMessages(snapshot.docs.map(doc => doc.data()))
        ))
    }
  }, [roomId])

  useEffect(() => {
    setSeed(Math.floor(Math.random() * 5000))
  }, [roomId])

  const sendMessage = e => {
    e.preventDefault()
    db
      .collection('rooms')
      .doc(roomId)
      .collection('messages')
      .add({
        message: input,
        name: user.displayName,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })
    setInput('')
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView()
  }

  useEffect(scrollToBottom, [messages])

  return (
    <div className='chat'>

      <div className='chat__header'>
        <Avatar src={`https://api.dicebear.com/8.x/pixel-art/svg?seed=${seed}`} />

        <div className='chat__headerInfo'>
          <h3>{ roomName }</h3>
          <p>
            Последний раз видели{' '}
            {
              messages.length
                ? moment(new Date(messages[messages.length - 1]?.timestamp?.toDate())).calendar()
                : ''
            }
          </p>
        </div>

        <div className='chat__headerRight'>
          <IconButton>
            <SearchOutlined />
          </IconButton>

          <IconButton>
            <AttachFile />
          </IconButton>

          <IconButton>
            <MoreVert />
          </IconButton>
        </div>

      </div>

      <div className='chat__body'>
        {messages.map((message, key) => (
          <p className={`chat__message ${message.name === user.displayName && 'chat__receiver'}`} key={key}>
            <span className='chat__name'>{ message.name }</span>
            { message.message }
            <span className='chat__timestamp'>
              {moment(new Date(message.timestamp?.toDate())).format('LT')}
            </span>
          </p>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className='chat__footer'>
        <InsertEmoticon />
        <form>
          <input
            value={input}
            onChange={e => setInput(e.target.value)}
            placeholder='Напишите сообщение'
            type='text'
          />
          <button onClick={sendMessage} type='submit'>Послать сообщение</button>
        </form>
        <Mic />
      </div>

    </div>
  )
}

export default Chat
