import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyBsACr3V1CUtP17KmOaK1p0hQKU2mHlZe8',
  authDomain: 'whatsapp-clone-d78b9.firebaseapp.com',
  databaseURL: 'https://whatsapp-clone-d78b9.firebaseio.com',
  projectId: 'whatsapp-clone-d78b9',
  storageBucket: 'whatsapp-clone-d78b9.appspot.com',
  messagingSenderId: '873295177011',
  appId: '1:873295177011:web:0bb5a2ddee03a1dc99ca46',
  measurementId: 'G-EHG89J9471'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebaseApp.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export { auth, provider }
export default db
