import React from 'react'
import './Login.css'
import { Button } from '@material-ui/core'
import { auth, provider } from './firebase'
import { actionTypes } from './reducer'
import { useStateValue } from './StateProvider'

function Login() {
  const [{}, dispatch] = useStateValue() // eslint-disable-line

  const signIn = () => {
    auth
      .signInWithPopup(provider)
      .then(result => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user
        })
      }).catch(e => {
        console.log(e)
      })
  }
  const signInAnonymously = () => {
    auth.signInAnonymously()
      .then(result => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user
        })
      }).catch(e => {
        console.log(e)
      })
  }

  return (
    <div className='login'>
      <div className='login__container'>
        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/800px-WhatsApp.svg.png' alt='' />

        <div className='login__text'>
          <h1>Sign In to WhatsApp Clone</h1>
        </div>

        <div className='login__buttons'>
          <Button onClick={signIn} className='login__button--green'>
            Sign In with Google
          </Button>
          <Button onClick={signInAnonymously} className='login__button--gray'>
            Sing In anonymously
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
